import { AssetProperties } from '.';
import { StorageProperties, Storage } from '../storage';
import { Meta } from '../meta';
import { CourseWithProgress } from '../courses';
import { ChallengeProperties } from '../challenges';
import { EntityType } from '@sidkik/global';
import { ChallengeWithProgress } from '../challenges/challenge-with-progress';
import { MinimizedBookingProperties } from '../scheduling';
import { ProductProperties } from '../catalog';
import { Platform, PlatformTrial } from '../platform';

export interface FlowSectionData {
  synopsis: string;
  title: string;
  course: string;
  challenge: string;
  session: string;
  platform: string;
  platformTrial: string;
  image?: AssetProperties;
  video?: AssetProperties;
  sections?: FlowSectionProperties[];
  courseInstance?: CourseWithProgress;
  challengeInstance?: ChallengeWithProgress;
  addableChallengeInstance?: ChallengeProperties;
  platformInstance?: Platform;
  platformTrialInstance?: PlatformTrial;
  overrideTitleAndImagery?: boolean;
  bookings?: MinimizedBookingProperties[];
  accessible?: boolean;
  accessibleProducts?: ProductProperties[];
  nonAccessibleTitle?: string;
  nonAccessibleSynopsis?: string;
}

export interface FlowSectionProperties extends StorageProperties {
  data: FlowSectionData;
  meta: Meta;
  id: string;
}

export class FlowSection extends Storage implements FlowSectionProperties {
  public override data!: FlowSectionData;

  constructor(options?: FlowSectionProperties, user?: string) {
    super(options, user, EntityType.FlowSection);
    this.update(options?.data);
  }

  public update(data?: FlowSectionData): void {
    this.data = { ...data } as unknown as FlowSectionData;
    this.data.sections = this.data.sections || [];
  }
}

import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType } from '@sidkik/global';

export interface FlowMapEntry {
  id: string;
  title: string;
  alwaysVisible?: boolean;
  active?: boolean;
}

export interface FlowMapData {
  entries: FlowMapEntry[];
}

export interface FlowMapProperties extends StorageProperties {
  data: FlowMapData;
  meta: Meta;
  id: string;
}

export class FlowMap extends Storage implements FlowMapProperties {
  public override data!: FlowMapData;

  constructor(options?: FlowMapProperties, user?: string) {
    super(options, user, EntityType.Map);
    this.update(options?.data);
  }

  public update(data?: FlowMapData): void {
    this.data = { ...data } as unknown as FlowMapData;
  }

  public addEntry(entry: FlowMapEntry): void {
    if (!this.data.entries) {
      this.data.entries = [];
    }
    this.data.entries?.push(entry);
    this.updateMeta();
  }

  public removeEntry(entry: FlowMapEntry): void {
    this.data.entries =
      this.data.entries?.filter((e) => e.id !== entry.id) ?? [];
    this.updateMeta();
  }

  public updateEntry(entry: FlowMapEntry): void {
    this.removeEntry(entry);
    this.addEntry(entry);
    this.updateMeta();
  }

  public setEntryOrder(entries: FlowMapEntry[]): void {
    this.data.entries = entries;
    this.updateMeta();
  }
}

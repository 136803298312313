import { inject, ModuleWithProviders, NgModule } from '@angular/core';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkListboxModule } from '@angular/cdk/listbox';
import { DropdownTriggerDirective } from './components/dropdown/dropdown-trigger.directive';

import { DropdownItemComponent } from './components/dropdown/dropdown-item.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { BreadcrumbItemComponent } from './components/breadcrumb/breadcrumb-item.component';
import { BreadcrumbHomeComponent } from './components/breadcrumb/breadcrumb-home.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { ProcessButtonComponent } from './components/process-button/process-button.component';
import { InputErrorComponent } from './components/input-error/input-error.component';
// import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';  - causes 1MB bloat with @zxcvbn-ts
import {
  InfoToastComponent,
  WarnToastComponent,
  ErrorToastComponent,
} from './components/toast';
import {
  defaultToastConfig,
  TOAST_CONFIG_TOKEN,
} from './components/toast/toast-config';
import { IconRegistryModule } from './icons.module';
import { IconComponent } from './components/icon/icon.component';
import { CommonModule, IMAGE_LOADER, NgOptimizedImage } from '@angular/common';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tabs/tab.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SortComponent } from './components/sort/sort.component';
import { SortableDirective } from './components/sort/sortable.directive';
import { PaginationComponent } from './components/pagination/pagination.component';
import { TableLoadingComponent } from './components/table-loading/table-loading.component';
import { FileDropDirective } from './directives/file-drop.directive';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ProgressDeterminateDirective } from './directives/progress.directive';
import { ComboBoxPanelComponent } from './components/combo-box/combo-box-panel.component';
import { ComboBoxTriggerDirective } from './components/combo-box/combo-box-trigger.directive';
import { ComboBoxComponent } from './components/combo-box/combo-box.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { MaskModule } from './components/mask/mask.module';
import { MaskDirective } from './components/mask/mask.directive';
import { IntegrationStatusComponent } from './components/integration-status/integration-status.component';
import { MediaSelectorPanelComponent } from './components/media-selector/media-selector-panel.component';
import { MediaSelectorTriggerDirective } from './components/media-selector/media-selector-trigger.directive';
import { MediaSelectorComponent } from './components/media-selector/media-selector.component';
import { AssetComponent } from './components/asset/asset.component';
import { BackgroundDirective } from './directives/background.directive';
import { UI_PIPES } from './pipes';
import { SlideOverComponent } from './components/slide-over/slide-over.component';
import { SlideOverTriggerDirective } from './components/slide-over/slide-over-trigger.directive';
import { SlideOverPanelComponent } from './components/slide-over/slide-over-panel.component';
import { DeleteButtonComponent } from './components/delete-button/delete-button.component';
import { DeleteConfirmationComponent } from './components/delete-button/delete-confirmation.component';
import { DialogModule } from '@angular/cdk/dialog';
import { IntersectionDirective } from './directives/intersection.directive';
import { LetDirective } from '@ngrx/component';

import {
  EditorJsChecklistComponent,
  EditorJsCodeComponent,
  EditorJsDelimiterComponent,
  EditorJsEmbedComponent,
  EditorJsHeaderComponent,
  EditorJsImageComponent,
  EditorJsLinkComponent,
  EditorJsListComponent,
  EditorJsNestedListComponent,
  EditorJsParagraphComponent,
  EditorJsQuoteComponent,
  EditorJsRawHTMLComponent,
  EditorJsRendererComponent,
  EditorJsTableComponent,
  EditorJsWarningComponent,
} from './components/editorjs';
import { EditorJsPersonalityComponent } from './components/editorjs/editor-js-personality/editor-js-personality.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SimplePieComponent } from './components/simple-pie/simple-pie.component';
import { VideoVimeoComponent } from './components/video-vimeo/video-vimeo.component';
import { CircleProgressComponent } from './components/circle-progress/circle-progress.component';
import { CIRCLE_PROGRESS_DEFAULTS_PROVIDER } from './components/circle-progress/circle-progess.config';
import {
  ButtonToggleComponent,
  ButtonToggleGroupComponent,
} from './components/button-toggle/button-toggle.component';
import { SparklineComponent } from './components/sparkline/sparkline.component';
import { TooltipContainerComponent } from './components/tooltip/tooltip-container.component';
import { TooltipDirective } from './components/tooltip/tooltip.directive';
import { EventMonthViewComponent } from './components/event-month-view/event-month-view.component';
import { EventYearViewComponent } from './components/event-year-view/event-year-view.component';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { ModerationResultsComponent } from './components/moderation-results/moderation-results.component';
import { TrackerComponent } from './components/trackers/tracker.component';
import { DynamicFormComponent } from './components/forms/dynamic-form.component';
import { TimeSpanComponent } from './components/time-span/time-span.component';
import { TypeaheadComponent } from './components/typeahead/typeahead.component';
import {
  APP_CONFIG,
  createImageLoader,
  SIDKIK_PROJECT,
  SIDKIK_TENANT,
} from '@sidkik/global';
import { UnsavedChangesGuard } from './guards/unsaved-changes.guard';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    CdkListboxModule,
    CdkScrollableModule,
    DialogModule,
    RouterModule,
    LetDirective,
    IconRegistryModule,
    ReactiveFormsModule,
    MaskModule.forRoot({
      validation: true,
    }),
    HighlightModule,
    NgOptimizedImage,
  ],
  exports: [
    DropdownTriggerDirective,
    DropdownComponent,
    DropdownItemComponent,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    BreadcrumbHomeComponent,
    AvatarComponent,
    ProcessButtonComponent,
    InputErrorComponent,
    // PasswordStrengthComponent, - causes 1MB bloat with @zxcvbn-ts
    InfoToastComponent,
    WarnToastComponent,
    IconComponent,
    TabsComponent,
    TabComponent,
    SortComponent,
    SortableDirective,
    PaginationComponent,
    TableLoadingComponent,
    FileDropDirective,
    IconComponent,
    FileUploadComponent,
    ProgressDeterminateDirective,
    ComboBoxPanelComponent,
    ComboBoxTriggerDirective,
    ComboBoxComponent,
    MediaSelectorPanelComponent,
    MediaSelectorTriggerDirective,
    MediaSelectorComponent,
    ToggleComponent,
    MaskDirective,
    IntegrationStatusComponent,
    AssetComponent,
    BackgroundDirective,
    SlideOverComponent,
    SlideOverTriggerDirective,
    SlideOverPanelComponent,
    DeleteButtonComponent,
    DeleteConfirmationComponent,
    IntersectionDirective,
    EditorJsChecklistComponent,
    EditorJsCodeComponent,
    EditorJsDelimiterComponent,
    EditorJsEmbedComponent,
    EditorJsHeaderComponent,
    EditorJsImageComponent,
    EditorJsLinkComponent,
    EditorJsListComponent,
    EditorJsNestedListComponent,
    EditorJsParagraphComponent,
    EditorJsPersonalityComponent,
    EditorJsQuoteComponent,
    EditorJsRawHTMLComponent,
    EditorJsTableComponent,
    EditorJsWarningComponent,
    EditorJsRendererComponent,
    ProgressBarComponent,
    SimplePieComponent,
    VideoVimeoComponent,
    CircleProgressComponent,
    ButtonToggleComponent,
    ButtonToggleGroupComponent,
    SparklineComponent,
    TooltipContainerComponent,
    TooltipDirective,
    EventYearViewComponent,
    EventMonthViewComponent,
    ModerationResultsComponent,
    TrackerComponent,
    DynamicFormComponent,
    TimeSpanComponent,
    TypeaheadComponent,

    ...UI_PIPES,
  ],
  declarations: [
    DropdownComponent,
    DropdownTriggerDirective,
    DropdownItemComponent,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    BreadcrumbHomeComponent,
    AvatarComponent,
    ProcessButtonComponent,
    InputErrorComponent,
    // PasswordStrengthComponent, - causes 1MB bloat with @zxcvbn-ts
    InfoToastComponent,
    WarnToastComponent,
    ErrorToastComponent,
    IconComponent,
    TabsComponent,
    TabComponent,
    SortComponent,
    SortableDirective,
    PaginationComponent,
    TableLoadingComponent,
    FileDropDirective,
    IconComponent,
    FileUploadComponent,
    ProgressDeterminateDirective,
    ComboBoxPanelComponent,
    ComboBoxTriggerDirective,
    ComboBoxComponent,
    ToggleComponent,
    IntegrationStatusComponent,
    MediaSelectorPanelComponent,
    MediaSelectorTriggerDirective,
    MediaSelectorComponent,
    AssetComponent,
    BackgroundDirective,
    SlideOverComponent,
    SlideOverTriggerDirective,
    SlideOverPanelComponent,
    DeleteButtonComponent,
    DeleteConfirmationComponent,
    IntersectionDirective,
    EditorJsChecklistComponent,
    EditorJsCodeComponent,
    EditorJsDelimiterComponent,
    EditorJsEmbedComponent,
    EditorJsHeaderComponent,
    EditorJsImageComponent,
    EditorJsLinkComponent,
    EditorJsListComponent,
    EditorJsNestedListComponent,
    EditorJsParagraphComponent,
    EditorJsPersonalityComponent,
    EditorJsQuoteComponent,
    EditorJsRawHTMLComponent,
    EditorJsTableComponent,
    EditorJsWarningComponent,
    EditorJsRendererComponent,
    ProgressBarComponent,
    SimplePieComponent,
    VideoVimeoComponent,
    CircleProgressComponent,
    ButtonToggleComponent,
    ButtonToggleGroupComponent,
    SparklineComponent,
    TooltipContainerComponent,
    TooltipDirective,
    EventYearViewComponent,
    EventMonthViewComponent,
    ModerationResultsComponent,
    TrackerComponent,
    DynamicFormComponent,
    TimeSpanComponent,
    TypeaheadComponent,

    ...UI_PIPES,
  ],
  providers: [
    UnsavedChangesGuard,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        // lineNumbersLoader: () => import('highlightjs-line-numbers.js'), // Optional, only if you want the line numbers
        languages: {
          arduino: () => import('highlight.js/lib/languages/arduino'),
          asciidoc: () => import('highlight.js/lib/languages/asciidoc'),
          bash: () => import('highlight.js/lib/languages/bash'),
          basic: () => import('highlight.js/lib/languages/basic'),
          csharp: () => import('highlight.js/lib/languages/csharp'),
          c: () => import('highlight.js/lib/languages/c'),
          cpp: () => import('highlight.js/lib/languages/cpp'),
          css: () => import('highlight.js/lib/languages/css'),
          dart: () => import('highlight.js/lib/languages/dart'),
          django: () => import('highlight.js/lib/languages/django'),
          dockerfile: () => import('highlight.js/lib/languages/dockerfile'),
          elixir: () => import('highlight.js/lib/languages/elixir'),
          elm: () => import('highlight.js/lib/languages/elm'),
          erlang: () => import('highlight.js/lib/languages/erlang'),
          go: () => import('highlight.js/lib/languages/go'),
          graphql: () => import('highlight.js/lib/languages/graphql'),
          groovy: () => import('highlight.js/lib/languages/groovy'),
          xml: () => import('highlight.js/lib/languages/xml'),
          json: () => import('highlight.js/lib/languages/json'),
          java: () => import('highlight.js/lib/languages/java'),
          javascript: () => import('highlight.js/lib/languages/javascript'),
          kotlin: () => import('highlight.js/lib/languages/kotlin'),
          lua: () => import('highlight.js/lib/languages/lua'),
          objectivec: () => import('highlight.js/lib/languages/objectivec'),
          php: () => import('highlight.js/lib/languages/php'),
          plaintext: () => import('highlight.js/lib/languages/plaintext'),
          powershell: () => import('highlight.js/lib/languages/powershell'),
          python: () => import('highlight.js/lib/languages/python'),
          r: () => import('highlight.js/lib/languages/r'),
          ruby: () => import('highlight.js/lib/languages/ruby'),
          rust: () => import('highlight.js/lib/languages/rust'),
          shell: () => import('highlight.js/lib/languages/shell'),
          swift: () => import('highlight.js/lib/languages/swift'),
          tsql: () => import('highlight.js/lib/languages/sql'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          vbnet: () => import('highlight.js/lib/languages/vbnet'),
          yml: () => import('highlight.js/lib/languages/yaml'),
        },
        // themePath: 'path-to-theme.css', // Optional, and useful if you want to change the theme dynamically
      },
    },
    {
      provide: IMAGE_LOADER,
      useFactory: () => {
        const projectId = inject(SIDKIK_PROJECT);
        const tenantId = inject(SIDKIK_TENANT);
        const appConfig = inject(APP_CONFIG);
        return createImageLoader(projectId, tenantId, appConfig);
      },
    },
  ],
})
export class UIModule {
  public static forRoot(
    toastConfig = defaultToastConfig
  ): ModuleWithProviders<UIModule> {
    return {
      ngModule: UIModule,
      providers: [
        CIRCLE_PROGRESS_DEFAULTS_PROVIDER,
        {
          provide: TOAST_CONFIG_TOKEN,
          useValue: { ...defaultToastConfig, ...toastConfig },
        },
      ],
    };
  }
}

import { Meta } from '../meta';
import { StorageProperties, Storage, omitUndefinedProps } from '../storage';
import { CodeInjection } from './code';
import { MetaData } from './metadata';
import { Cover } from './cover';
import { PageLayout } from './page-layout';
import {
  ComboBoxOption,
  EntityType,
  NavigationType,
  PageType,
} from '@sidkik/global';
import { PageMapEntry } from './page-map';

export const PageTypeLookup: ComboBoxOption[] = [
  {
    value: PageType.Site,
    label: 'Site Page',
    description:
      'General purpose page to provide information about your site or business.',
  },
  {
    value: PageType.Landing,
    label: 'Landing / Sales Page',
    description: 'A page to promote a product or service.',
  },
  {
    value: PageType.Home,
    label: 'Home Page',
    description: 'Primary page for your site.',
  },
  {
    value: PageType.PrivacyPolicy,
    label: 'Privacy Policy',
    description: 'Privacy policy for your site.',
  },
  {
    value: PageType.TermsOfUse,
    label: 'Terms of Use',
    description: 'Terms of use for your site.',
  },
];

export const NavigationTypeLookup: ComboBoxOption[] = [
  {
    value: NavigationType.Full,
    label: 'Full',
    description: 'Full navigation.',
  },
  {
    value: NavigationType.Minimal,
    label: 'Minimal',
    description: 'Minimal navigation.',
  },
  {
    value: NavigationType.None,
    label: 'None',
    description: 'No navigation.',
  },
];

export interface PageData {
  title: string;
  slug: string;
  isHome: boolean;
  pageType: PageType;
  headerNavigation: NavigationType;
  footerNavigation: NavigationType;
  template: string;
  content?: PageLayout;
  codeHeader?: CodeInjection;
  codeFooter?: CodeInjection;
  metadata?: MetaData;
  cover?: Cover;
  allowOverflow?: boolean;
  isIndexable?: boolean; // this is used to determine if the page should be indexed by search engines
}

export interface PageProperties extends StorageProperties {
  data: PageData;
  meta: Meta;
  id: string;
}

export class Page extends Storage implements PageProperties {
  public override data!: PageData;

  constructor(options?: PageProperties, user?: string) {
    super(options, user, EntityType.Page);
    this.update(options?.data);
  }

  public update(data?: PageData): void {
    this.data = { ...data } as unknown as PageData;
    this.data.content = this.data.content || {};
  }

  public toMapEntry(): PageMapEntry {
    return omitUndefinedProps({
      id: this.id,
      title: this.data.title,
      slug: this.data.slug,
      isIndexable: this.data.isIndexable,
    });
  }
}

import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TrailItem } from '../services/breadcrumbs.service';

import * as GlobalActions from './global.actions';
import * as GlobalSelectors from './global.selectors';
import { APP_CONFIG, AppConfig } from '../models/config';
import { CookieService } from '../services/cookie.service';
import { DOCUMENT } from '@angular/common';
import {
  CookieAffiliateCode,
  CookiePromoCode,
  NavigationType,
} from '../constants';

@Injectable()
export class GlobalFacade {
  breadcrumbTrail$ = this.store.pipe(
    select(GlobalSelectors.getBreadcrumbTrail)
  );
  userId$ = this.store.pipe(select(GlobalSelectors.getUserId));
  jwt$ = this.store.pipe(select(GlobalSelectors.getJWT));
  headerNavigation$ = this.store.pipe(
    select(GlobalSelectors.getHeaderNavigation)
  );
  footerNavigation$ = this.store.pipe(
    select(GlobalSelectors.getFooterNavigation)
  );

  constructor(
    private readonly store: Store,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private readonly cookieService: CookieService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  insertAffiliateCodeCookie(affiliateCode: string): void {
    if (this.cookieService.get(CookieAffiliateCode) === '') {
      logger.trace(
        'global:global.facade:insertAffiliateCodeCookie',
        'adding affiliate code cookie',
        affiliateCode
      );
      this.cookieService.set(
        CookieAffiliateCode,
        affiliateCode,
        this.appConfig.affiliate?.ttlDays ?? 30,
        '/',
        undefined,
        true,
        'Strict'
      );
      // add tracking event for affiliate experience
      // track registration event
    }
  }

  insertPromoCodeCookie(promoCode: string): void {
    logger.trace(
      'global:global.facade:insertPromoCodeCookie',
      'adding promo code cookie',
      promoCode
    );
    this.cookieService.set(
      CookiePromoCode,
      promoCode,
      30,
      '/',
      undefined,
      true,
      'Strict'
    );
  }

  addBreadCrumbs(items: TrailItem[]): void {
    this.store.dispatch(GlobalActions.addBreadcrumb({ items }));
  }

  setHeaderNavigation(navigationType: NavigationType): void {
    this.store.dispatch(GlobalActions.setHeaderNavigation({ navigationType }));
  }

  setFooterNavigation(navigationType: NavigationType): void {
    this.store.dispatch(GlobalActions.setFooterNavigation({ navigationType }));
  }
}

import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import {
  EntityType,
  HeaderMenuAlignment,
  ItemsDisplayType,
  MyActivitiesSections,
  NavigationEntry,
  ShopSections,
} from '@sidkik/global';

export interface NavigationMapData {
  header: NavigationEntry[];
  footer: NavigationEntry[];
  headerMenuAlignment?: HeaderMenuAlignment;
  myActivitiesItemsDisplayType?: ItemsDisplayType;
  shopItemsDisplayType?: ItemsDisplayType;
  shopSections?: ShopSections[];
  myActivitiesSections?: MyActivitiesSections[];
}

export interface NavigationMapProperties extends StorageProperties {
  data: NavigationMapData;
  meta: Meta;
  id: string;
}

export class NavigationMap extends Storage implements NavigationMapProperties {
  public override data!: NavigationMapData;

  constructor(options?: NavigationMapProperties, user?: string) {
    super(options, user, EntityType.Map);
    this.update(options?.data);
  }

  public update(data?: NavigationMapData): void {
    this.data = { ...data } as unknown as NavigationMapData;
  }
}

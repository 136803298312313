export interface ComboBoxOption {
  value: any;
  label: string;
  description?: string;
  filtered?: boolean;
}

export enum MenuEntryDisplay {
  None = 'none',
  Link = 'header-nav-item',
  PillPrimary = 'header-pill-primary-flat',
  PillPrimaryWithBorder = 'header-pill-primary-border',
  PillSecondary = 'header-pill-secondary-flat',
  PillSecondaryWithBorder = 'header-pill-secondary-border',
  PillNeutral = 'header-pill-neutral-flat',
  PillNeutralWithBorder = 'header-pill-neutral-border',
  ButtonPrimary = 'btn-primary',
  ButtonSecondary = 'btn-secondary',
  ButtonPrimaryMuted = 'btn-sub-primary',
  ButtonNeutral = 'btn-neutral',
}

export interface NavigationEntry {
  title: string;
  url: string;
  group?: string;
  display?: MenuEntryDisplay;
  staticId?: string;
}

export const MenuEntryDisplayOptions: ComboBoxOption[] = [
  { value: MenuEntryDisplay.None, label: 'Do Not Display' },
  { value: MenuEntryDisplay.Link, label: 'Link' },
  { value: MenuEntryDisplay.PillPrimary, label: 'Pill Primary' },
  {
    value: MenuEntryDisplay.PillPrimaryWithBorder,
    label: 'Pill Primary With Border',
  },
  { value: MenuEntryDisplay.PillSecondary, label: 'Pill Secondary' },
  {
    value: MenuEntryDisplay.PillSecondaryWithBorder,
    label: 'Pill Secondary With Border',
  },
  { value: MenuEntryDisplay.PillNeutral, label: 'Pill Neutral' },
  {
    value: MenuEntryDisplay.PillNeutralWithBorder,
    label: 'Pill Neutral With Border',
  },
  { value: MenuEntryDisplay.ButtonPrimary, label: 'Button Primary' },
  { value: MenuEntryDisplay.ButtonSecondary, label: 'Button Secondary' },
  { value: MenuEntryDisplay.ButtonPrimaryMuted, label: 'Button Primary Muted' },
  { value: MenuEntryDisplay.ButtonNeutral, label: 'Button Neutral' },
];

export enum HeaderMenuAlignment {
  Left = 'tw-justify-start',
  Center = 'tw-justify-center',
  Right = 'tw-justify-end',
}

export const HeaderMenuAlignmentOptions: ComboBoxOption[] = [
  { value: HeaderMenuAlignment.Left, label: 'Left' },
  { value: HeaderMenuAlignment.Center, label: 'Center' },
  { value: HeaderMenuAlignment.Right, label: 'Right' },
];

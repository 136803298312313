import { coerceElement } from '@angular/cdk/coercion';
import { DragRef, DropListRef } from '@angular/cdk/drag-drop';

// A few lines of code used for debugging (saved to avoid having to re-write them)
// let reflistToString = (list: DropListRef[]) => JSON.stringify(list.map(ref => coerceElement(ref.element).id));
// console.log("Targets: " + reflistToString(targets));

export function installPatch() {
  DropListRef.prototype._getSiblingContainerFromPosition = function (
    item: DragRef,
    x: number,
    y: number
  ): DropListRef | undefined {
    // Hack to access private member DropListRef._siblings
    const this__siblings = (this as any)._siblings as DropListRef[];

    // Possible targets include siblings and this
    const targets = [this, ...this__siblings];

    // Only consider targets where the drag postition is within the dom rect
    // (this avoids calling enterPredicate on each possible target)
    const matchingTargets = targets.filter((ref) => {
      // Hack to access private member DropListRef._domRect
      const ref__domRect = (ref as any)._domRect as DOMRect | undefined;
      return ref__domRect && isInsideDomRect(ref__domRect, x, y);
    });

    // Stop if no targets match the coordinates
    if (matchingTargets.length === 0) return undefined;

    // Order candidates by DOM hierarchy and z-index
    const orderedMatchingTargets = orderByHierarchy(matchingTargets);

    // The drop target is the last matching target in the list
    const matchingTarget =
      orderedMatchingTargets[orderedMatchingTargets.length - 1];

    // Return the target if it is a sibling and can recieve the item
    return matchingTarget != this && matchingTarget._canReceive(item, x, y)
      ? matchingTarget
      : undefined;
  };
}

// Not possible to improt isInsideDomRect from @angular/cdk/drag-drop/dom-rect
function isInsideDomRect(domRect: DOMRect, x: number, y: number) {
  const { top, bottom, left, right } = domRect;
  return y >= top && y <= bottom && x >= left && x <= right;
}

// Order a list of DropListRef so that for nested pairs, the outer DropListRef
// is preceding the inner DropListRef. Should probably be ammended to also
// sort by Z-level.
function orderByHierarchy(refs: DropListRef[]) {
  // Build a map from HTMLElement to DropListRef
  const refsByElement: Map<HTMLElement, DropListRef> = new Map();
  refs.forEach((ref) => {
    refsByElement.set(coerceElement(ref.element), ref);
  });

  // Function to identify the closest ancestor among th DropListRefs
  const findAncestor = (ref: DropListRef) => {
    let ancestor = coerceElement(ref.element).parentElement;

    while (ancestor) {
      if (refsByElement.has(ancestor)) return refsByElement.get(ancestor);

      ancestor = ancestor.parentElement;
    }

    return undefined;
  };

  // Node type for tree structure
  type NodeType = { ref: DropListRef; parent?: NodeType; children: NodeType[] };

  // Add all refs as nodes to the tree
  const tree: Map<DropListRef, NodeType> = new Map();
  refs.forEach((ref) => {
    tree.set(ref, { ref: ref, children: [] });
  });

  // Build parent-child links in tree
  refs.forEach((ref) => {
    const parent = findAncestor(ref);

    if (parent) {
      const node = tree.get(ref);
      const parentNode = tree.get(parent);

      node!.parent = parentNode;
      parentNode!.children.push(node!);
    }
  });

  // Find tree roots
  const roots = [...tree.values()].filter((node) => !node.parent);

  // Function to recursively build ordered list from roots and down
  const buildOrderedList = (nodes: NodeType[], list: DropListRef[]) => {
    list.push(...nodes.map((node) => node.ref));

    nodes.forEach((node) => {
      buildOrderedList(node.children, list);
    });
  };

  // Build and return the ordered list
  const ordered: DropListRef[] = [];
  buildOrderedList(roots, ordered);
  return ordered;
}

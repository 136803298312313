import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType } from '@sidkik/global';

export interface PageMapEntry {
  id: string;
  title: string;
  slug: string;
  isIndexable?: boolean; // this is used to determine if the page should be indexed by search engines
}

export interface PageMapData {
  entries: PageMapEntry[];
}

export interface PageMapProperties extends StorageProperties {
  data: PageMapData;
  meta: Meta;
  id: string;
}

export class PageMap extends Storage implements PageMapProperties {
  public override data!: PageMapData;

  constructor(options?: PageMapProperties, user?: string) {
    super(options, user, EntityType.Map);
    this.update(options?.data);
  }

  public update(data?: PageMapData): void {
    this.data = { ...data } as unknown as PageMapData;
  }

  public addEntry(entry: PageMapEntry): void {
    if (!this.data.entries) {
      this.data.entries = [];
    }
    this.data.entries?.push(entry);
    super.updateMeta();
  }

  public removeEntry(entry: PageMapEntry): void {
    this.data.entries =
      this.data.entries?.filter((e) => e.id !== entry.id) ?? [];
    super.updateMeta();
  }

  public updateEntry(entry: PageMapEntry): void {
    this.removeEntry(entry);
    this.addEntry(entry);
    super.updateMeta();
  }

  public getEntry(id: string): PageMapEntry | undefined {
    return this.data.entries?.find((e) => e.id === id);
  }

  public getSlugById(id: string): string | undefined {
    const entry = this.getEntry(id);
    return entry?.slug;
  }

  public getEntryBySlug(slug: string): PageMapEntry | undefined {
    return this.data.entries?.find((e) => e.slug === slug);
  }
}

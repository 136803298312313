import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { filter, map, tap } from 'rxjs';
import { GlobalFacade } from './global.facade';

@Injectable()
export class GlobalEffects {
  private affiliateCodeReceived = false;
  private promoCodeReceived = false;
  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        filter(() => !this.affiliateCodeReceived && !this.promoCodeReceived),
        filter((action: RouterNavigationAction) => {
          const routerState = action.payload.routerState;
          return (
            routerState.root.queryParams['a_code'] !== undefined ||
            routerState.root.queryParams['p_code'] !== undefined
          );
        }),
        map((action: RouterNavigationAction) => {
          const routerState = action.payload.routerState.root.queryParams;
          return {
            affiliateCode: routerState['a_code'],
            promoCode: routerState['p_code'],
          };
        }),
        tap(({ affiliateCode, promoCode }) => {
          if (affiliateCode) {
            this.affiliateCodeReceived = true;
            this.globalFacade.insertAffiliateCodeCookie(affiliateCode);
          }
          if (promoCode) {
            this.promoCodeReceived = true;
            this.globalFacade.insertPromoCodeCookie(promoCode);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private globalFacade: GlobalFacade
  ) {}
}
